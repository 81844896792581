.popup-menu {
  position: relative;
  display: inline-block;
}

.dropdown {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  width: 200px;
  text-align: left;
  margin-left: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.selected-item {
  padding: 8px;
  font-size: 14px;
}

.menu-list {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 200px;
  z-index: 10000; /* Ensure it's on top of other elements */
}

.menu-item {
  padding: 8px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}
