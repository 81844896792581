.plans-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  color: white;
  max-width: 85vw;
  width: fit-content;
  flex: 0 0 auto;
}

.plans > h2 {
  font-size: 28px;
  text-align: center;
  margin: 0; /* Remove extra margin */
}

.plan-group {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  justify-content: flex-start;
  scroll-behavior: smooth;
  width: 100%;
}

.plan {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  border: 1px solid #2c346e;
  border-radius: 8px;
  width: 500px;
  max-height: 600px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #22222260;
  text-align: left;
}

.plan > h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.plan-features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: auto;
  box-sizing: border-box;
}

.plan-feature li {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
  list-style: none;
}

.plan-feature li::before {
  content: '•';
  margin-right: 8px;
  color: rgb(125, 21, 129);
}

.intervals {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  width: 100%;
  text-align: center;
  margin-top: auto;
  padding-top: 20px; /* Add space above the button area */
}

.interval-cost {
  /* font-size: 24px; */
  font-weight: 600;
  margin-bottom: 6px;
}

.button-container {
  display: flex;
  height: 95px;
  align-items: center;
  justify-content: center;
}

.plans .button {
  margin-top: 0px;
  padding: 0px 20px;
  background-color: #8100dd36;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  max-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  white-space: normal;
  word-break: break-word !important;
  word-wrap: normal;
  width: 100%;
}

.plans .button:hover {
  background-color: #8100dda0;
  border-color: #555;
}

.plans .button:focus-visible {
  outline: -webkit-focus-ring-color auto 5px !important;
  outline: 2px solid #4a90e2 !important;
  background-color: #8100dda0;
}

.selected-plan {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
}

.coming_soon {
  position: relative; /* Allow adding the legend with pseudo-elements */
  color: #ddd; /* Slightly less bright text */
}

.coming_soon::after {
  content: '\0000A0Coming\0000A0soon!'; /* Add the legend */
  position: absolute;
  white-space: nowrap; /* Ensure the text stays on one line */
  left: 100%; /* Position it to the right of the span */
  margin-left: 8px; /* Add spacing between the text and the legend */
  color: #aaa; /* Faded text color for the legend */
  font-size: 0.9em; /* Slightly smaller font size */
  font-weight: normal; /* Ensure it doesn't stand out too much */
  font-style: italic; /* Optional: Makes the text stand out as different */
}

.selected-plan-info {
  padding: 32px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-color-light);
}

.selected-plan-info h2 {
  margin: 0 0 16px 0;
  font-size: 28px;
  color: var(--text-color-primary);
}

.selected-plan-info .plan-cost {
  font-size: 24px;
  font-weight: bold;
  color: var(--text-color-primary);
  margin: 16px 0;
}

.selected-plan-info .plan-description {
  font-size: 16px;
  color: var(--text-color-secondary);
  margin: 16px 0;
  line-height: 1.5;
}

.payment-form-container {
  padding: 24px 32px;
}

/* Style the MUI Dialog */
.MuiDialog-paper {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  max-width: 600px !important;
  margin: 16px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25) !important;
}

/* Stripe Elements styling */
.card-element-wrapper {
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color-light);
  margin: 16px 0;
}

/* Button positioning */
.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px 32px;
  border-top: 1px solid var(--border-color);
  margin-top: 24px;
}

.button-group button {
  min-width: 120px;
}

/* Update Stripe Elements theme */
.StripeElement {
  background-color: var(--background-color-light) !important;
  padding: 12px !important;
  border-radius: 4px !important;
  border: 1px solid var(--border-color) !important;
}

.StripeElement--focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px var(--primary-color-light) !important;
}
