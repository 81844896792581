.Monaco_Editor_Outer_Wrapper {
  width: 100%;
  height: 100%;
}

.Monaco_Editor_Main_Container1 {
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensure borders are included in width/height */
}

/* Media queries for responsiveness */
@media (max-width: 1440px) {
  .Monaco_Editor_Main_Container1 {
    max-width: 100%;
    height: 100%;
    margin: auto; /* Center the editor horizontally */
  }
}

@media (max-width: 1024px) {
  .Monaco_Editor_Main_Container1 {
    max-width: 100%; /* Adjust as needed */
    height: 100%; /* Adjust height for medium-sized screens */
    margin: auto; /* Center the editor horizontally */
  }
}

@media (max-width: 768px) {
  .Monaco_Editor_Main_Container1 {
    max-width: 100%; /* Adjust as needed */
    height: 100; /* Adjust height for smaller screens */
    margin: auto; /* Center the editor horizontally */
  }
}

@media (max-width: 480px) {
  .Monaco_Editor_Main_Container1 {
    max-width: 100%; /* Adjust as needed */
    height: 100; /* Adjust height for even smaller screens */
    margin: auto; /* Center the editor horizontally */
  }
}
