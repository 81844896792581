.main-body {
  height: 100vh;
}

/* .main-component {
  background-color: #292f44;
} */

.main-container {
  display: flex;
  height: calc(100vh - 65px);
  /* background-color: #292f44; */
  background-color: #363f56; /* Some effect is filtering this color to get it to the above color on screen. */
}

.sidebar-stack {
  width: 45px;
  height: 100%;
}

.sidebar-icons {
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-grid {
  height: 100%;
}

.chatbox-paper {
  height: 100%;
  margin: 0;
  padding: 0;
}

.chatbox-container {
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(19, 23, 38, 1),
    rgba(54, 62, 86, 1)
  );
}
