.admin-dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  color: #222; /* Dark, readable text */
}

.admin-nav {
  margin: 2rem 0;
  border-bottom: 2px solid #333;
}

.nav-tab {
  background: none;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  margin-right: 1rem;
  color: #222; /* Dark text */
}

.nav-tab.active {
  border-bottom-color: #333;
  font-weight: 600;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.data-table th,
.data-table td {
  padding: 1rem;
  text-align: left;
}

.data-table th {
  background: #333;
  color: #fff;
  font-weight: 500;
}

.data-table tr {
  border-bottom: 1px solid #eee; /* Subtle separator instead of full borders */
}

.data-table tr:nth-child(even) {
  background: #f9f9f9;
}

.feature-list {
  list-style: disc;
  padding-left: 1.5rem;
  margin: 0;
}

.edit-button {
  background: #333;
  color: white;
  border: none; /* No border */
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.edit-button:hover {
  background: #444;
}

.coming_soon {
  position: relative; /* Allow adding the legend with pseudo-elements */
  color: #666; /* Slightly less bright text */
  font-style: italic; /* Optional: Makes the text stand out as different */
}

.coming_soon::after {
  content: '\0000A0Coming\0000A0soon!'; /* Add the legend */
  position: absolute;
  white-space: nowrap; /* Ensure the text stays on one line */
  left: 100%; /* Position it to the right of the span */
  margin-left: 8px; /* Add spacing between the text and the legend */
  color: #aaa; /* Faded text color for the legend */
  font-size: 0.9em; /* Slightly smaller font size */
  font-weight: normal; /* Ensure it doesn't stand out too much */
}
