.Sidebar_Main_Container {
  height: calc(100vh - 65px);
  overflow-y: hidden;
  border-top-left-radius: 10px;
  background: linear-gradient(
    to right,
    rgba(11, 15, 27, 1),
    rgba(24, 28, 43, 1)
  );
  position: relative;
  padding-right: 10px;
}

.Sidebar_Main_Container {
  overflow-y: scroll;
  margin-right: 0px;
}

.Sidebar_Main_Container::-webkit-scrollbar {
  width: 10px;
}

.Sidebar_Main_Container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
}
.Sidebar_Main_Container:hover::-webkit-scrollbar-thumb {
  background-color: #2d3857;
}

.Explorer_Input {
  margin: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input_Box {
  width: 80%;
  background: transparent;
  border: none;
  margin: 2%;
  outline: none;
  color: #a0a8c4;
  opacity: 70%;
}

.Explorer_Input > img {
  margin: 2%;
}
