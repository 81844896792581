/* Connection status styling */
.connection_state {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #f1f1f1;
  background-color: #2b2f42;
  border-top: 1px solid #2e3244;
}

.connection_state .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.connection_state .connected {
  background-color: #00ff00;
}

.connection_state .disconnected {
  background-color: #ff0000;
}
