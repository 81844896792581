/* .secondaryButton{
    background: #414765;
    color: white;
    border-radius: 3px;
} */

/* CustomButton.css */

.custom-button.secondaryButton {
  background: #414765;
  color: white;
  border-radius: 3px;
  transition: background-color 0.3s ease; /* Add a smooth transition for the hover effect */
}

.custom-button.secondaryButton:hover {
  background-color: #1e1f2b; /* Change the background color on hover */
}

.custom-button.primaryButton {
  background: #694dc2;
  color: white;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.custom-button.primaryButton:hover {
  background-color: #1e1f2b; /* Change the background color on hover */
}
