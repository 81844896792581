.account-validator-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 280px;
}

.account-validator-button {
  background: #eee;
  color: black;
  border: 1px solid #333;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.account-validator-success {
  color: #060;
  font-weight: 700;
}

.account-validator-error {
  color: #600;
  font-weight: 700;
}
