.AICommands_Main_Box1_Content > p {
  color: #a0a8c4;
  padding-left: 20px;
  font-size: 14px;
}

.MonacoEditor_Component {
  position: absolute;
}

/* Main container for AI Commands */
.AICommands_Main_Container {
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
}

/* Top box with bot icon and title */
.AICommands_Main_Box1 {
  height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    rgba(27, 31, 49, 1),
    rgba(34, 38, 60, 1)
  );
}

/* Content box inside the top container */
.AICommands_Main_Box1_Content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 7vh;
  margin: 20px;
}

.AIBot_Icon {
  padding-left: 10px;
  height: 24px;
  width: 24px;
}

/* Text and angle down icon for the Commands toggle */
.AICommands_Main_Box1_Toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
}

.AICommands_Main_Box1_Content_SmallAngleDown {
  display: flex;
  cursor: pointer;
}

.AICommands_Main_Box1_Content_SmallAngleDown p {
  color: #a0a8c4;
  font-size: 13px;
}

.AICommands_Main_Box1_Content_SmallAngleDown img {
  padding-left: 5px;
}

/* Main content area below the top box */
.AICommands_Content_Container {
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(19, 23, 37, 1),
    rgba(36, 40, 63, 1)
  );
  height: 90vh;
}

/* Overlay for CommandCollapse component */
.AICommands_Content_Container > div:first-child {
  z-index: 10;
  position: absolute;
  background: linear-gradient(to top, rgba(36, 40, 63, 1), rgba(19, 23, 37, 1));
}

/* Monaco Editor container */
.MonacoEditor_Component {
  height: 100%;
  width: 100%;
}
