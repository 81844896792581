.body {
  background-color: var(--background-color);
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

.account-validator-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}

.account-validator-heading {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
}

.account-validator-error {
  color: #ff0000;
  margin-bottom: 20px;
}

.account-validator-success {
  color: #00ff00;
  margin-bottom: 20px;
}

.account-validator-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 100%;
}

.account-validator-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account-validator-input {
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  border: 1px solid #333333;
}
