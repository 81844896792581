.activity-indicator {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 36px;
  width: 120px;
  /* border: 1px solid #ccc;  */
  color: #f1f1f1;
  background-color: #2b2f42;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Icon width */
  height: 30px; /* Icon height */
  pointer-events: none;
}

.activity-text {
  position: fixed;
  margin-left: 42px;
  color: #f1f1f1;
  /* color: #5a6181; */
}
