/* src/styles/ManageMappingModal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapping-modal {
  background: white;
  padding: 2rem;
  min-width: 400px;
}

.mapping-modal h2 {
  margin: 0 0 1rem 0;
}

.mapping-modal h3 {
  margin: 0 0 2rem 0;
  color: #666;
}

.mapping-group {
  margin-bottom: 1.5rem;
}

.mapping-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.mapping-group select {
  width: 100%;
  padding: 0.5rem;
}

.modal-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-actions button {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.modal-actions button:last-child {
  background: #333;
  color: white;
  border: none;
}
