/* ChatBox container */
.chat-box-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  border: 1px solid #7a819c;
  height: calc(100vh - 64px);
  /* overflow: hidden; */
  /* align-items: flex-start; */
}

/* Message list scrollable area */
.message-list-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  /* min-height: 70vh; */
}

/* Input container fixed to the bottom */
.message-input-container {
  padding: 0px;
}

/* Message bubble styles */
.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Sent messages (from the current user) */
.message.sent {
  justify-content: flex-start; /* Left justify the whole message */
  flex-direction: row-reverse; /* Avatar on the left of the message */
}

/* Received messages (from others) */
.message.received {
  justify-content: flex-start; /* Right justify the whole message */
  flex-direction: row; /* Avatar on the right of the message */
}

/* Avatar bubble styling */
.avatar-bubble {
  background-color: #b3e6a5; /* More saturated version of sent message bubble color */
  border-radius: 12px; /* Rounded rectangle */
  min-width: 40px; /* Minimum size */
  min-height: 40px;
  max-width: 80px; /* Maximum size */
  height: auto; /* Let the height adapt to the content */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  color: #333;
  overflow: hidden;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  font-size: 14px; /* Back to original size */
  padding: 5px; /* Add padding for flexibility */
}

/* Dynamic shrinking of text */
.avatar-bubble span {
  font-size: calc(14px); /* Original text size */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Message bubble styling */
.message-bubble {
  max-width: 70%;
  padding: 10px;
  border-radius: 12px;
  background-color: #e1ffc7;
  color: #333;
}

/* Sent message bubble styling */
.message.sent .message-bubble {
  background-color: #e1ffc7;
  color: #333;
}

/* Sent avatar bubble - more saturated than message bubble */
.message.sent .avatar-bubble {
  background-color: #b3e6a5; /* A more saturated version of the sent message bubble color */
}

/* Received message bubble styling */
.message.received .message-bubble {
  background-color: #e3d9f7; /* Purple tone for received messages */
  color: #333;
}

/* Received avatar bubble - more saturated than received message bubble */
.message.received .avatar-bubble {
  background-color: #c6a8f0; /* Purple tone for received avatars */
}

/* Message input container */
.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #2b2f42;
  border-top: 1px solid #2e3244;
}

/* Input field */
.message-input input {
  flex-grow: 1;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  background-color: #1c1f2e;
  color: white;
  font-size: 14px;
  outline: none;
  height: 40px; /* Fix the height */
}

/* Placeholder text styling */
.message-input input::placeholder {
  color: #a0a8c4; /* Lighter gray color to match comp */
  opacity: 1; /* Ensure the placeholder text is fully visible */
  font-size: 14px; /* Ensure consistent font size */
}

/* Send button */
.message-input button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5a60ea;
  border: none;
  border-radius: 50%; /* Make it perfectly circular */
  padding: 10px;
  width: 48px; /* Fix button size */
  height: 48px; /* Fix button size */
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}

.message-input button svg {
  fill: white;
  width: 20px;
  height: 20px;
}

.message-input button:hover {
  background-color: #4e54cc;
}

.status-panel {
  display: flex;
  align-self: flex-start;
  justify-content: flow-start;
  align-items: center;
  gap: 10px;
  margin-top: 0;
  top: 0;
  background-color: #2b2f42;
  width: 100%;
}

/* Media queries to ensure avatar bubble doesn't get too large or too small */
@media (min-width: 100px) {
  .avatar-bubble span {
    font-size: calc(14px); /* Original text size */
  }
}

@media (min-width: 600px) {
  .avatar-bubble span {
    font-size: 14px;
  }
}
