.folder-container {
  margin: 0;
}

.folder-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding-left: 5px;
  width: 100%;
  margin-left: -20px;
}

.folder-element.selected {
  background: linear-gradient(
    to right,
    rgba(89, 99, 131, 40%),
    rgba(89, 99, 131, 0%)
  );
  background-position-x: -5px;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.spacer {
  width: 20px;
}

.folder-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding-left: 5px;
}

.entry-text {
  font-size: 13px;
  color: #a0a8c4;
  margin-left: 10px;
  padding-left: 4px;
}

.entry-text.selected {
  color: white;
  font-weight: 700;
}

.folder-box,
.files {
  height: 22px;
  align-items: center;
}

.folder-box > img {
  margin-left: calc(10px + var(--indent-level) * 1px);
  margin-right: -3px;
}

.disclosure-icon {
  width: 22px;
  margin-left: -24px;
  padding-left: -4px;
  vertical-align: middle;
}

.files-container {
  margin: 200px;
  display: flex;
  flex-direction: row;
}

.files {
  display: flex;
  color: #a0a8c4;
  border-radius: 10px;
  justify-content: flex-start;
}

/* .files > img {
  margin-left: calc(10px + var(--indent-level)px);
} */

@keyframes glowFadeOld {
  0% {
    box-shadow: 0 0 8px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(68, 83, 216, 0);
  }
  50% {
    box-shadow: 0 0 8px rgba(68, 83, 216, 1);
  }
}

@keyframes glowFade {
  0% {
    color: rgba(255, 165, 0, 1);
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
  }
  100% {
    color: inherit;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0);
  }
  10% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 1);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  90% {
    color: rgba(255, 165, 0, 1);
  }
}

.folder-box.glowing,
.file-container.glowing,
.entry-text.glowing {
  animation: glowFade 2s ease-out; /* Plays glowFade animation */
}
