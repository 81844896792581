@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes glow {
  0%,
  100% {
    filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0));
  }
  50% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
  }
}

.pulsing-icon {
  display: inline-block;
  transition: opacity 0.3s ease;
}

.pulsing-icon.active {
  animation:
    pulse 2s infinite ease-in-out,
    glow 2s infinite ease-in-out;
}

.pulsing-icon.idle {
  animation: none;
  opacity: 0.6; /* Dims the icon when idle */
}
