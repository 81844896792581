:root {
  --primary-400: rgba(8, 8, 8, 1);
  --accent: rgba(239, 236, 230, 1);
  --primary-200: rgba(115, 115, 115, 1);
  --primary-100: rgba(186, 186, 186, 1);
  --primary-300: rgba(51, 51, 51, 1);
  --text-regular-normal-font-family: 'DM Sans', Helvetica;
  --text-regular-normal-font-weight: 400;
  --text-regular-normal-font-size: 16px;
  --text-regular-normal-letter-spacing: 0px;
  --text-regular-normal-line-height: 150%;
  --text-regular-normal-font-style: normal;
  --heading-5-font-family: 'DM Sans', Helvetica;
  --heading-5-font-weight: 700;
  --heading-5-font-size: 24px;
  --heading-5-letter-spacing: -0.24px;
  --heading-5-line-height: 139.9999976158142%;
  --heading-5-font-style: normal;
  --text-large-bold-font-family: 'DM Sans', Helvetica;
  --text-large-bold-font-weight: 700;
  --text-large-bold-font-size: 20px;
  --text-large-bold-letter-spacing: 0px;
  --text-large-bold-line-height: 150%;
  --text-large-bold-font-style: normal;
  --text-small-normal-font-family: 'DM Sans', Helvetica;
  --text-small-normal-font-weight: 400;
  --text-small-normal-font-size: 14px;
  --text-small-normal-letter-spacing: 0px;
  --text-small-normal-line-height: 150%;
  --text-small-normal-font-style: normal;
  --screenglow: 0px 0px 100px 20px rgba(86, 108, 223, 0.2);
  --shadow-small: 0px 1px 2px 0px rgba(35, 39, 46, 0.08);
  --background-gradient: linear-gradient(180deg, #090909 0%, #121212 100%);
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: var(--text-regular-normal-font-family);
  background: var(--background-gradient);
  color: var(--primary-100);
}

.header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px 96px;
  position: relative;
  width: 100%;
}

/* Wrapper for full-page layout */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content area */
.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Footer styles */
.footer {
  background-color: #2c346e;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #efece6;
  border-top: 1px solid #4a90e2;
}

.light {
  height: 816px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  min-width: 1440px;
}

.cool-background {
  background-image: url('../../public/img/u5391529864-light-grey-geometric-mesh-overlaying-a-dark-blue-00.png');
  position: 'absolute';
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
  min-width: 1vw;
  background-size: cover;
  height: auto;
  z-index: -1;
}

.menu {
  align-items: flex-start;
  display: inline-flex;
  gap: 56px;
  position: relative;
}

.text-wrapper {
  white-space: nowrap;
  color: var(--accent);
  font-family: var(--text-regular-normal-font-family);
  line-height: var(--text-regular-normal-line-height);
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  /* margin-left: 100px;
  margin-top: 30px; */
}

.nav-buttons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  color: var(--accent);
  font-family: var(--text-regular-normal-font-family);
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.nav-buttons .button {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--accent);
}

.button {
  cursor: pointer;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background: var(--background-gradient);
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90%;
  box-shadow: var(--shadow-small);
  color: var(--accent);
  position: relative;
}

.dialog-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--accent);
}
