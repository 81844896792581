/* .Header_Main_Project_Button{
    font-family: 'Montserrat';
    font-weight: 700;
} */

/* .Header_Custom_Menu {
    overflow-y: scroll; 
    margin-right: 0px; 
}
.Header_Custom_Menu::-webkit-scrollbar {
    width: 10px; 
}
.Header_Custom_Menu::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px; 
}
.Header_Custom_Menu:hover::-webkit-scrollbar-thumb {
    background-color: #2d3857; 
} */

#ProjectButton {
  font-family: 'Montserrat';
  font-weight: 600;
  color: #7d88ac;
}

.identity {
  font-family: 'Montserrat';
  border: 1px solid #adb0c0; /* White border */
  border-radius: 8px; /* Rounded corners */
  padding: 4px 8px; /* Inner spacing */
  background-color: transparent; /* Ensure it matches the header's background */
  display: inline-flex; /* Align items horizontally */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  color: inherit; /* Inherit color for consistency */
}
