/* src/styles/FeatureSelect.css */
.feature-select {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-search input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selected-features {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.selected-feature {
  background: #f0f0f0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.remove-feature {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1;
}

.feature-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-group {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.product-group h4 {
  margin: 0 0 0.5rem 0;
  color: #666;
}

.feature-option {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
}

.feature-option:hover {
  background: #f5f5f5;
}

.feature-name {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.availability-label {
  color: #666;
  font-size: 0.9em;
  font-weight: normal;
}

.feature-description {
  font-size: 0.9em;
  color: #666;
  margin-top: 0.25rem;
}

.create-new-option {
  padding: 1rem;
  color: #0066cc;
  cursor: pointer;
  border-top: 1px solid #eee;
}

.create-new-option:hover {
  background: #f5f5f5;
}
