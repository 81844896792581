/* src/styles/EditPlanForm.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-plan-form {
  background: white;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #222;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.feature-input,
.interval-input {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.remove-button {
  background: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.form-actions button {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.form-actions button[type='submit'] {
  background: #333;
  color: white;
  border: none;
}

.form-actions button[type='button'] {
  background: none;
  border: none;
}
