/* MobiusBoard.css */

/* Board Container */
.mobius-board-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  font-family: 'Montserrat', sans-serif;
}

.mobius-board-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mobius-board-header h2 {
  margin: 0;
  color: #333;
  font-weight: 600;
  font-size: 1.5rem;
}

.mobius-board-actions {
  display: flex;
  gap: 8px;
}

/* Board Layout */
.mobius-board {
  display: flex;
  height: calc(100% - 60px);
  overflow-x: auto;
  padding: 1rem;
  gap: 1rem;
}

/* Empty State */
.mobius-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
}

.mobius-empty-state h3 {
  margin-bottom: 1rem;
  color: #333;
}

/* Columns */
.mobius-column {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 300px;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.mobius-column-header {
  padding: 1rem;
  background-color: #fff;
  border-bottom: 3px solid;
}

.mobius-column-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.mobius-column-sections {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem;
}

/* Sections */
.mobius-section {
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease;
}

.mobius-section.mobius-drop-target {
  background-color: rgba(0, 163, 224, 0.05);
}

.mobius-section-header {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fcfcfc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.mobius-section-header h4 {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 500;
  color: #555;
}

.mobius-section-content {
  padding: 0.75rem;
}

/* Notes */
.mobius-note {
  position: relative;
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: grab;
  min-height: 3rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition:
    box-shadow 0.2s ease,
    transform 0.1s ease;
}

.mobius-note:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mobius-note.mobius-dragging {
  opacity: 0.6;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.mobius-note-content {
  font-size: 0.9rem;
  line-height: 1.4;
  word-break: break-word;
  white-space: pre-wrap;
  color: #030303;
}

.mobius-note-actions {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  display: none;
  gap: 4px;
}

.mobius-note:hover .mobius-note-actions {
  display: flex;
}

.mobius-note-action-button {
  background: transparent;
  border: none;
  color: #666;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.mobius-note-action-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

/* Add Note Button */
.mobius-add-note-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  background-color: transparent;
  border: 1px dashed #ccc;
  border-radius: 4px;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobius-add-note-button:hover {
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #999;
  color: #333;
}

/* Note Editor Modal */
.mobius-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mobius-note-editor {
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.mobius-note-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.mobius-note-editor-header h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.mobius-note-textarea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid #eee;
  resize: vertical;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.95rem;
}

.mobius-note-textarea:focus {
  outline: none;
}

.mobius-color-selector {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.mobius-color-selector label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #555;
}

.mobius-color-options {
  display: flex;
  gap: 0.5rem;
}

.mobius-color-option {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.mobius-color-option:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.mobius-color-option.selected {
  border: 2px solid #333;
}

.mobius-note-editor-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 0.5rem;
}

/* Buttons */
.mobius-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobius-primary-button {
  background-color: #00a3e0;
  color: white;
}

.mobius-primary-button:hover {
  background-color: #0087b9;
}

.mobius-primary-button:disabled {
  background-color: #b3e0f2;
  cursor: not-allowed;
}

.mobius-secondary-button {
  background-color: #f5f5f5;
  color: #333;
}

.mobius-secondary-button:hover {
  background-color: #e0e0e0;
}

.mobius-refresh-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 1.2rem;
  background-color: #f5f5f5;
}

.mobius-close-button {
  background: transparent;
  font-size: 1.5rem;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mobius-board {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .mobius-column {
    min-width: 270px;
    width: 270px;
  }
}

/* Loading and Error Components */
.mobius-loading-spinner,
.mobius-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
}

.mobius-error-message {
  color: #e53935;
}
