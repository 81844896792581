.FeaturedVerb_Para {
  color: #a0a8c4;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
}

#Main_Container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.Main_Container_Dynamic_Button {
  margin: 0.8%;

  color: #bb9cff;
  border: none;
  min-width: 70px;
  padding: 4px 6px;
  border-radius: 0; /* Reset default border-radius */

  /* Rounded left and right sides */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  /* Add your other styles here */
  background: #31395a;
  font-size: 12px;
}
