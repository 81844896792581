/* src/styles/StripeIntegration.css */
.stripe-integration {
  padding: 1rem;
}

.stripe-integration .data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.stripe-integration .data-table th,
.stripe-integration .data-table td {
  padding: 1rem;
  text-align: left;
}

.stripe-integration .data-table th {
  background: #333;
  color: #fff;
  font-weight: 500;
}

.stripe-integration .data-table tr {
  border-bottom: 1px solid #eee;
}

.stripe-integration .data-table tr:nth-child(even) {
  background: #f9f9f9;
}

.stripe-integration .edit-button {
  background: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.stripe-integration .edit-button:hover {
  background: #444;
}
