.MuiList-root {
  background: linear-gradient(to left, #1f233b, #181d2b);
}

.custom-menu-paper {
  overflow-y: scroll;
  background: linear-gradient(to left, #1f233b, #181d2b);
}

.custom-menu-paper::-webkit-scrollbar {
  width: 10px;
}

.custom-menu-paper::-webkit-scrollbar-thumb {
  background-color: #2d3857;
  border-radius: 5px;
}

.custom-menu-paper:hover::-webkit-scrollbar-thumb {
  background: #2d3857;
}

.menu-item {
  color: #a0a8c4 !important;
  font-size: 14px;
  font-family: Montserrat;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.shortcut {
  opacity: 0.6;
  font-size: 0.85em;
  margin-left: auto;
}

.shortcut-spacer {
  width: 30px;
}
