.Landing_Page_Main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

/* Subscription Section CSS */

.Subscription_Section_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to top, #242960, #020205);
}

.Subscription_Section_Box1 {
  margin: 2%;
}

.Subscription_Section_Box1 > h1 {
  text-align: center;
  color: white;
}

.Subscription_Section_Box1 > h1 > span {
  color: #5867ef;
}

.Subscription_Section_Box2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2%;
  margin-bottom: 5%;
}

.Subscription_Section_Box2_Free {
  height: 22rem;
  width: 20%;
  background: transparent;
  border: 1px solid white;
}

.Subscription_Section_Box2_Free_Content {
  margin: 8%;
  text-align: left;
}

.Subscription_Section_Box2_Free_Content > h1 {
  font-size: 5rem;
  color: white;
  margin: 0;
  padding: 0;
  display: inline;
}

.Subscription_Section_Box2_Free_Title {
  margin: 10px;
  color: #a0a8c4;
}

.Subscription_Section_Box2_Free_Content > h1 > sup {
  font-size: 1rem;
}

.Subscription_Section_Box2_Free_Button {
  width: 100%;
  height: 2.5rem;
  background: transparent;
  border: 2px solid white;
  color: white;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.Subscription_Section_Box2_Free_Content > ul > li {
  margin-top: 2%;
  color: white;
}

.Subscription_Section_Box2_Priceline {
  height: 22rem;
  width: 20%;
  background: transparent;
  border: 1px solid white;
}

.Subscription_Section_Box2_Priceline_Title {
  color: #bb9cff;
  margin: 10px;
}

.Subscription_Section_Box2_Enterprise_Title {
  color: #5bdec9;
  margin: 10px;
}

.Subscription_Section_Box2_Priceline_Button {
  width: 100%;
  height: 2.5rem;
  background: transparent;
  color: white;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  background-color: #5867ef;
}

.Subscription_Section_Box2_Enterprise {
  height: 22rem;
  width: 20%;
  background: transparent;
  border: 1px solid white;
}

.Subscription_Section_Box2_Enterprise_Button {
  width: 100%;
  height: 2.5rem;
  background: transparent;
  color: white;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  background-color: #5867ef;
}
