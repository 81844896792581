.dialogBox {
  /* Ensure transparency in the Dialog */
  background-color: transparent;
}

.dialogPaper {
  background: linear-gradient(
    to bottom,
    rgba(27, 31, 49, 1),
    rgba(34, 38, 60, 1)
  );
  border-radius: 8px;
  overflow: hidden;
}

/* Dialog Title Styling */
.dialogTitle {
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.formLabel {
  color: #d0d6e4; /* Brighter shade for better contrast */
  font-size: 14px;
  font-weight: 700; /* Bold text */
  display: inline-block;
}

.requiredField {
  margin-left: 0.2rem;
  color: #694dc2;
  font-size: 14px;
  font-weight: 700; /* Bold the asterisk as well */
}

/* Required field label - brighter and bold */
.formLabel.required {
  color: #e8ecf4;
  font-weight: 900; /* Bold the required field labels */
}

.formField {
  color: #a0a8c4;
}

.formField input::placeholder {
  color: #a0a8c4;
}

.formField input {
  color: #ffffff;
}

.formField {
  color: #a0a8c4;
}

.formField input::placeholder {
  color: #a0a8c4;
}

.formField input {
  color: #ffffff;
}

.formField textarea::placeholder {
  color: #a0a8c4;
}

.formField textarea {
  color: #ffffff;
}

.hidden {
  display: none;
}

/* Center the loading spinner */
.centerBox {
  display: flex;
  justify-content: center;
}

/* Margin for input fields */
.margin {
  margin: 2%;
}

.inputContainer {
  display: flex;
  margin: 2%;
  width: 100%;
}

.formField {
  flex-grow: 1; /* Allows the form field to take up all available space */
  width: 100% !important; /* Ensures full width */
}

.formField .MuiFormControl-root {
  width: 100% !important;
  max-width: 100% !important;
}

.formField .MuiFormControl-root {
  width: 100% !important;
  max-width: 100% !important;
}

.formField .MuiInputBase-root {
  width: 100% !important;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
