/* src/styles/PaymentForm.css */
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.payment-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  min-width: 300px;
  max-width: 500px;
}

.account-section {
  color: var(--form-text);
  background-color: var(--form-background);
}

.account-section.section-title {
  color: var(--form-text);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.payment-form {
  border: 1px solid;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--form-background);
}

.form-section {
  margin-bottom: 2rem;
}

.form-field {
  width: 100%;
  margin-bottom: 1rem;
}

.seats-field {
  max-width: 200px;
}

.card-element-wrapper {
  padding: 1rem;
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.payment-form input,
.payment-form textarea {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

.submit-button {
  cursor: pointer;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 5px;
  height: 30px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.error-message {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: var(--error-color);
}

.form-field-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.seats-section {
  margin: 24px 0;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.payment-section {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.total-cost {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0;
  color: var(--text-color-primary);
}
